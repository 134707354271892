import React from 'react'
import bg from "../img/bg3.jpg"
import logo from "../img/logo.png"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";


function Whos() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
    <div 
    style={{
        backgroundImage: `url(${bg})`,
        padding:"10px",
        position:"relative",
        width:"100wh",
        height:"400px",
        backgroundPosition:"center",
        backgroundSize:"cover",
        backgroundAttachment:"fixed",
       
       }}
    >
     <div className='flex justify-end p-8'   data-aos="fade-up"
              data-aos-duration="3000">
        <img src={logo} alt="logo" />
     </div>
    </div>
    </>
  )
}

export default Whos