import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Card() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="flex  sm:h-96 myfont m-4 " >
        <div class="flex flex-wrap justify-center gap-4 m-auto">
          <div class="card w-full sm:w-80 bg-neutral text-neutral-content" data-aos="fade-left"  data-aos-duration="1500">
            <div class="card-body items-center text-center">
              <h2 class="card-title text-3xl font-bold">Presale</h2>
              <p class="text-2xl">
                25% Cheaper then Launch price. instant profit!..
              </p>
            </div>
          </div>
          <div class="card w-full sm:w-80 bg-neutral text-neutral-content" data-aos="fade-up-right"  data-aos-duration="1500">
            <div class="card-body items-center text-center">
              <h2 class="card-title text-3xl font-bold">Liquidity</h2>
              <p class="text-2xl">51% presale go to liquidity</p>
            </div>
          </div>
          <div class="card w-full sm:w-80 bg-neutral text-neutral-content" data-aos="fade-up-right"  data-aos-duration="1500">
            <div class="card-body items-center text-center">
              <h2 class="card-title text-3xl font-bold">Pocket Money</h2>
              <p class="text-2xl">
                Momma wants you to do well. from 520 to $520M with Mommasol!
                Dive into the $mommasol Presale
              </p>
            </div>
          </div>
          <div class="card w-full sm:w-80 bg-neutral text-neutral-content" data-aos="fade-up-right"  data-aos-duration="1500">
            <div class="card-body items-center text-center">
              <h2 class="card-title text-3xl font-bold">Buy Now</h2>
              <p class="text-2xl">Presale phase started.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;
