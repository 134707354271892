import herebg from "../img/heropng.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Hero() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div
        className="flex flex-col md:flex-row items-center justify-center text-center  text-slate-900 myfont p-20 relative"
        style={{
          background: "#85dbd9",
        }}
      >
        <div className="left md:w-1/2  "  >
          <h1 className="text-5xl md:text-8xl md:absolute md:top-28  md:left-50 font-bold">
            MOMMA SOL
          </h1>
          <p className="text-3xl md:text-5xl" data-aos="fade-left"  data-aos-duration="1500">
            Here to teach all these memes a lesson!
          </p>
          <div>
         
          </div>
        </div>

        <div className="right md:w-1/2 z-10 w-40" data-aos="fade-left"  data-aos-duration="1500">
          <img
            src={herebg}
            alt="hero"
            className="md:h-auto object-cover sm:w-2/3 w-40"
          />
        </div>
      </div>
    </>
  );
}

export default Hero;
