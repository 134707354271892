import React from "react";
import bg from "../img/bgg.jpg"
import logo from "../img/logo5.png"
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function About() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="flex sm:h-96 myfont"
       style={{
        backgroundImage: `url(${bg})`,
        padding:"10px",
        position:"relative",
        height:"auto"
       }}
      
      >
        <div class="flex flex-wrap justify-center gap-4 m-auto text-black">
          <div class="card w-full sm:w-80 backdrop-blur-xl bg-white/30  ">
            <div class="card-body items-center text-center " data-aos="fade-up-right"  data-aos-duration="1500">
              <p class="text-2xl pt-4">
                Tokens will be airdropped to your wallet after the presale
                completes. Date TBC..
              </p>
            </div>
          </div>
          <div class="card w-full sm:w-80 backdrop-blur-xl bg-white/30  ">
            <div class="card-body items-center text-center" data-aos="fade-up-right"  data-aos-duration="1500">
              <p class="text-2xl pt-4" >
                Keep an eye on your wallet for the arrival of $MOMMASOL. Follow
                our Twitter for further announcements
              </p>
            </div>
          </div>
          <div class="card w-full sm:w-80 backdrop-blur-xl bg-white/30  ">
            <div class="card-body items-center text-center" data-aos="fade-up-right"  data-aos-duration="1500">
              <h2 class="card-title text-3xl font-bold">MOMMA SOLS PLANS</h2>
              <p class="">
                🔥 Big Partnerships 12+ Youtubers with 2,15M Subs
              </p>
              <p>💎 Based team, OG marketers that did 30-120x projects </p>
              <p> 🫵 No private sale & No Tax</p>
              <p> 💥 Tier 1 & 2 CEX Listings </p>
              <p>🤙 Top Callers, Influencers </p>
              <p>
                🔥 DexTools, DexView, DexScreener & Ave Trending 📊 All Solana
                Trending Bots{" "}
              </p>
              <p>⚡️ Fast-Track CMC & CG</p>
              <p>📢 Ads on Major Crypto Websites </p>
              <p>🗣️ 24/7 Non-stop Shilling</p>
            </div>
          </div>

          <div className=" absolute w-28 bottom-0 left-0">
            <img src={logo} alt="logo"  />
          </div>
         
        </div>
      </div>
    </>
  );
}

export default About;
