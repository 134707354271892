import mo from "../img/moa1.png";
import bg from "../img/bg.jpg";
import "./style.css"; // Ensure you have this CSS file
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Next() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="myfont">
        <div>
          <div
            style={{
              width: "50%",
              margin: "auto",
              position: "relative",
            }}
          >
            <div className="hover:bg-orange-600 cursor-pointer">
              <img src={bg} alt="bg" />
            </div>

            <div
              className="absolute top-80 max-sm:w-40 max-sm:top-10 "
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <img src={mo} alt="logo" />
            </div>

            <div className="mt-4 pt-4 sm:pt-10 text-center">
              <h1 className="text-2xl sm:text-5xl font-semibold ">
                WHAT HAPPENS NEXT?
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Next;
