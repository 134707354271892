import {
  WalletAdapterNetwork,
  WalletNotConnectedError,
} from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import {
  GlowWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {
  clusterApiUrl,
  Transaction,
  SystemProgram,
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
} from "@solana/web3.js";
import React, { FC, ReactNode, useMemo, useCallback, useState } from "react";
import { actions, utils, programs, NodeWallet, Connection } from "@metaplex/js";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

let thelamports = 0;
let theWallet = "79bBahQ26YZhweiZuEtjoW6woVeafxGGE1JAHJbBX3Lb";

function Buy() {
  useEffect(() => {
    AOS.init();
  }, []);
  let [lamports, setLamports] = useState("");

  let [loading, setLoading] = useState(false);

  // const { connection } = useConnection();
  const connection = new Connection(
    "https://mainnet.helius-rpc.com/?api-key=2467e3d9-8136-4fa0-97fd-f1fb172a9aa4"
  );
  const { publicKey, sendTransaction } = useWallet();

  const onClick = useCallback(async () => {
    if (!publicKey) throw new WalletNotConnectedError();
    setLoading(true); // Start loading

    try {
      const balance = await connection.getBalance(publicKey);
      console.log(balance / LAMPORTS_PER_SOL);

      let lamportsI = LAMPORTS_PER_SOL * lamports;
      console.log(publicKey.toBase58());
      console.log("lamports sending: {}", lamportsI);

      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey(theWallet),
          lamports: lamportsI,
        })
      );

      const signature = await sendTransaction(transaction, connection);

      await connection.confirmTransaction(signature, "processed");
      console.log("your amount received! we will send soon!");
      alert("Transaction successful!");
    } catch (error) {
      console.error(error);
      alert("Transaction failed. Please try again.");
    } finally {
      setLoading(false); // Ensure loading is set to false regardless of the outcome
    }
  }, [publicKey, sendTransaction, connection, lamports]);

  // function setTheLamports(e){
  //   console.log(Number(e.target.value));
  //   setLamports(Number(e.target.value));
  //   lamports = e.target.value;
  //   thelamports = lamports;
  // }

  // console.log("l",lamports)

  return (
    <>
      <div className="flex myfont m-4 p-2 sm:h-full ">
        <div className="m-auto">
          <div className="  bg-teal-400 rounded-md sm:p-20">
            <div>
              <h1
                className="sm:text-3xl pt-4 text-2xl text-center pb-8"
                data-aos="fade-up-right"
                data-aos-duration="1500"
              >
                How To Buy With SOL
              </h1>
            </div>
            <div
              className="flex flex-col items-center"
              data-aos="fade-up-right"
              data-aos-duration="1500"
            >
              <WalletMultiButton />
              <br />
              <div
                className="p-2"
                data-aos="fade-up-right"
                data-aos-duration="1500"
              >
                <input
                  value={lamports}
                  type="number"
                  onChange={(e) => setLamports(e.target.value)}
                  placeholder="Enter Value"
                  className=" w-48 mt-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                  style={{
                    padding: "20px",
                    margin: "10px",
                    fontSize: "20px",
                  }}
                  step="0.01" // Allow decimal values up to two decimal places
                />
                {loading ? (
                  <button
                    disabled
                    className="mt-4 p-2 bg-teal-900 text-white rounded-md hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50"
                    style={{
                      padding: "10px 30px",
                      fontSize: "15px",
                      margin: "10px",
                    }}
                  >
                    Loading...
                  </button>
                ) : (
                  <button
                    onClick={onClick}
                    className="mt-4 p-2 bg-teal-900 text-white rounded-md hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50"
                    style={{
                      padding: "10px 30px",
                      fontSize: "15px",
                      margin: "10px",
                    }}
                  >
                    Buy Now
                  </button>
                )}
              </div>
            </div>
            <div className="mt-8">
              <div className="flex  px-2">
                <div className="flex-shrink-0">
                  {/* Use an icon or a number as a bullet point */}
                  <span className="text-lg leading-6 font-semibold text-gray-700">
                    1.
                  </span>
                </div>
                <div
                  className="ml-3 text-lg text-gray-700 "
                  data-aos="fade-up-right"
                  data-aos-duration="1500"
                >
                  Press ‘Buy Now’ above and connect wallet
                </div>
              </div>
              <br />
              <br />
              <div className="flex px-2">
                <div className="flex-shrink-0 card">
                  {/* Use an icon or a number as a bullet point */}
                  <span className="text-lg leading-6 font-semibold text-gray-700">
                    2.
                  </span>
                </div>
                <div
                  className="ml-3  text-lg text-gray-700"
                  data-aos="fade-up-right"
                  data-aos-duration="1500"
                >
                  Enter amount of SOL to swap for $MOMMASOL
                  <br />
                  <b>You can buy MOMMASOL from a minimum of 0.01 Solana.</b>
                </div>
              </div>
              <br />
              <br />
              <div className="flex items-center px-2">
                <div className="flex-shrink-0">
                  {/* Use an icon or a number as a bullet point */}
                  <span className="text-lg leading-6 font-semibold text-gray-700">
                    3.
                  </span>
                </div>
                <div
                  className="ml-3  text-lg text-gray-700 "
                  data-aos="fade-up-right"
                  data-aos-duration="1500"
                >
                  Hit ‘Buy Now’ again and confirm transaction
                </div>
              </div>
              <br />
              <br />
              <div>
                <p
                  className="pt-4 p-2 text-lg"
                  data-aos="fade-up-right"
                  data-aos-duration="1500"
                >
                  If you can’t connect your wallet, just send SOL to and tokens
                  will be airdropped to the sending wallet.
                </p>
                <br />{" "}
                <p className="text-center tex-2xl">
                  <b>
                    {" "}
                    79bBahQ26YZhweiZuEtjoW
                    <br />
                    6woVeafxGGE1JAHJbBX3Lb
                  </b>
                </p>
                <br />
                <p
                  className="p-2 text-lg"
                  data-aos="fade-up-right"
                  data-aos-duration="1500"
                >
                  <strong>NB:</strong> Don’t use a CEX to send SOL! Use your own
                  wallet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Buy;
